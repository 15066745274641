var Environment = getEnvironment();

function getEnvironment() {
    const PLATFORM = process.env.REACT_APP_PLATFORM;

    let platform  //getPlatform();
    if (PLATFORM === "FF-INT") {
      platform = "development";
    } else if (PLATFORM === "CFL") {
      platform = "test";
    } else if (PLATFORM === "STAGE") {
      platform = "stage";
    } else if (PLATFORM === "PROD") {
      platform = "production";
    } 
    const clientInfo = {
      "stage" : [
          { brand : "FF", country: 'PH', clientId: '29B1AEBF-6E88-4C6B-8A40-58F0D70DD786' },
          { brand : "CF", country: 'PH',clientId: '1BC13DAC-5F7B-43D9-A0EE-4A823CFB63F5' }
      ],
      "production": [
          { brand : "FF", country: 'PH', clientId: 'C5204611-3032-4205-A87A-5CED9AD099E2' },
          { brand : "CF", country: 'PH',clientId: '67DA2115-E647-4251-812B-0ACD960ACAA5' }
      ]
    }
    const SSO = {
        clientInfo:clientInfo[platform],
        OPServer: platform === 'stage' ? 'https://id.uat.circuithq.com/' : 'https://id.circuithq.com/',
        redirect_uri: '/callback',
        scope: 'openid profile member-data offline_access',
        userInfoEndpoint: 'connect/userinfo',
        extra: {prompt: 'consent', access_type: 'offline'}
    }
    var _Environments = {
        production: { SSO, BASE_URL: 'https://api.wexer.com/', API_KEY: 'a0ddf4dc5b0b4b628e5a4c27b93c988e', JWPLAYER:'95riWD5s.js',API_SECRET: '711ff01003764889987158b329c7e6c0' },
        stage: { SSO, BASE_URL: 'https://stage.api.wexer.com/', API_KEY: '1234123412341234',JWPLAYER:'wUkkXJdm.js', API_SECRET: '573572796441127398913495789868468206481' },
        development: { SSO, BASE_URL: 'https://ff-int.api.wexer.com/', API_KEY: '1234123412341234',JWPLAYER:'HofBP5zj.js', API_SECRET: '573572796441127398913495789868468206481' },
        test: { SSO, BASE_URL: "https://test.api.wexer.com/", API_KEY: '50703717552133197901571613251413029814',JWPLAYER:'HofBP5zj.js', API_SECRET: '51749401073929517144660310291806967949' },
        uat: { SSO, BASE_URL: 'https://uat.api.wexer.com/', API_KEY: '1234123412341234',JWPLAYER:'wUkkXJdm.js', API_SECRET: '573572796441127398913495789868468206481' },
    
    }
    return _Environments[platform];
  }

module.exports = Environment 
 